export default {
    STATE: {
        FEEDBACK_REPORT_FAULT_MOBILE: {
            v: '006'
        //    系统提示异常
        }
    },
    BOOL: {
        YES: {
            v: 1,
            is: true,
            name: '是',
            arrearsName: '有欠款',
            enableName: '禁用',
            enableColor: 'red',
            buttonType: 'primary',
            buttonName: '启用',
            readName: '已读',
            plan: '有',
            stagColor: '2',
            settlement: '已结算'
        },
        NO: {
            v: 2,
            is: false,
            name: '否',
            arrearsName: '无欠款',
            enableName: '启用',
            enableColor: 'blue',
            buttonType: 'danger',
            buttonName: '禁用',
            readName: '未读',
            plan: '无',
            stagColor: '1',
            settlement: '待结算'
        }
    },
    // 调研模板分制枚举
    RESEARCH_GRADE: {
        FIVE_GRADE: {
            v: 1,
            name: '五分值'
        },
        TEN_GRADE: {
            v: 2,
            name: '十分值'
        }
    },
    // 调研模板题型
    RESEARCH_TYPE: {
        SCORE: {
            v: 1,
            name: '评分'
        },
        SELECTOR: {
            v: 2,
            name: '单选'
        },
        MULTI_SELECTOR: {
            v: 3,
            name: '多选'
        },
        Q_AND_A: {
            v: 4,
            name: '问答'
        },
        CSAT: {
            v: 5,
            name: 'CSAT',
            TEXT: '客户满意度'
        },
        NPS: {
            v: 6,
            name: 'NPS',
            TEXT: '净推荐值'
        }
    },
    // 调研满意度
    RESEARCH_HAPPY: {
        VERY_SATISFIED: {
            v: 1,
            name: '非常满意'
        },
        SATISFIED: {
            v: 2,
            name: '满意'
        },
        GENERAL: {
            v: 3,
            name: '一般'
        },
        DISSATISFIED: {
            v: 4,
            name: '不满意'
        },
        VERY_DISSATISFIED: {
            v: 5,
            name: '非常不满意'
        }
    },
    // 回访类型
    RETURNVISITTYPE:{
        PREVIEW:{
            v:1,
            name:"预览",
            msg:'当前为预览界面，回答题目将不做统计'
        },
        VIEW:{
            v:2,
            name:'查看'
        },
        EVALUATION:{
            v:3,
            name:'人工回访'
        },
        MANUAL_SURVEY:{
            v:4,
            name:'回访备注'
        }
    },
    FEEDBACK_TYPE: {
        STANDARD_SERVICE: {
            v: 1,
            name: '标准反馈'
        },
        SEND_REPAIR_SERVICE: {
            v: 2,
            name: '寄修反馈'
        },
        NEW_WORK_ORDER:{
            v:3,
            name:'新工单反馈'
        }
    },
    getItem (enums, v) {
        return Object.values(enums).find(item => item.v === v)
    },
    getField (enums, v, fieldName) {
        return this.getItem(enums, v)[`${fieldName}`]
    },
    getName (enums, v) {
        return this.getField(enums, v, 'name')
    }
}
