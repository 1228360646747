import Vue from 'vue'
import Antd from 'ant-design-vue';
import App from './App.vue'
import 'ant-design-vue/dist/antd.less';
import router from './router'
import * as echarts from 'echarts';
Vue.config.productionTip = false
Vue.use(Antd);
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
import iconUrl from '/iconconfig'
import { Icon, Popup, Picker, Toast, Empty } from 'vant';

Vue.use(Toast);
Vue.use(Icon);
Vue.use(Popup);
Vue.use(Picker);
Vue.use(Empty)

Vue.use(Viewer, {
  defaultOptions: {
    zIndex: 9999
  }
})
Vue.prototype.$echarts = echarts
// web原生组件兼容 [iconpark兼容,]
Vue.config.ignoredElements = ['iconpark-icon']
// 动态添加iconUrl
const script = document.createElement('script')
script.type = 'text/javascript'
script.src = iconUrl
document.getElementsByTagName('head')[0].appendChild(script)
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

Vue.filter('isEmpty', function (value) {
  if (!value) return '无'
  return value
})

new Vue({
  el: '#app',
  router,
  render: h => h(App)
}).$mount('#app')
